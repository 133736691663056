<template>
  <div style="width: 100%;">
    <div
      style="border-bottom: silver solid 1px;height: 50px;display: flex;justify-content: center;justify-items: center">
      <span style="height: 50px;line-height: 50px;font-size: 16px">商品出库</span>
    </div>
    <div class="app-container">
      <!--表格渲染-->
      <div class="cont">
        <div style="width: 45%;;height: 100%">
          <el-tabs type="border-card" v-model="active" @tab-click="tabClick" :before-leave="lanjie">
            <el-tab-pane label="卖品" name="0">
              <!--工具栏-->
              <div class="head-container">
                <!-- 搜索 -->
                <el-select v-model="categoryId" placeholder="请选择分类" size="mini" style="width: 200px;" clearable
                           class="filter-item">
                  <el-option
                    v-for="item in categoryList"
                    :key="item.id"
                    :label="item.catName"
                    :value="item.id">
                  </el-option>
                </el-select>
                <el-input v-model="skuName" clearable size="mini" placeholder="输入名称" style="width: 200px;"
                          class="filter-item"/>
                <el-input v-model="skuNo" clearable size="mini" placeholder="输入货号" style="width: 200px;"
                          class="filter-item"/>
                <el-button class="filter-item" style="margin-left: 5px" size="mini" type="success" icon="el-icon-search"
                           @click="toSearch">搜索
                </el-button>
              </div>
              <div style="height: 567px;">
                <el-table :data="list" style="width: 100%" @row-click="setSku">
                  <el-table-column prop="catName" label="分类"/>
                  <el-table-column prop="skuName" label="名称"/>
                  <el-table-column prop="skuNo" label="货号"/>
                </el-table>
                <div class="pagination">
                  <el-pagination
                    background
                    @current-change="skuPageSearch"
                    :pager-count="7"
                    layout="total, prev, pager, next"
                    :total="skuPagination.count"
                    :page-count="skuPagination.pageCount"
                    :current-page="skuPagination.pageNumber"
                  ></el-pagination>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="非卖品" name="1">
              <!--工具栏-->
              <div class="head-container">
                <!-- 搜索 -->
                <el-select v-model="categoryId" placeholder="请选择分类" size="mini" style="width: 200px;" clearable
                           class="filter-item">
                  <el-option
                    v-for="item in categoryList"
                    :key="item.id"
                    :label="item.catName"
                    :value="item.id">
                  </el-option>
                </el-select>
                <el-input v-model="skuName" clearable size="mini" placeholder="输入名称" style="width: 200px;"
                          class="filter-item"/>
                <el-input v-model="skuNo" clearable size="mini" placeholder="输入货号" style="width: 200px;"
                          class="filter-item"/>
                <el-button class="filter-item" style="margin-left: 5px" size="mini" type="success" icon="el-icon-search"
                           @click="toSearch">搜索
                </el-button>
              </div>
              <div style="height: 567px;">
                <el-table :data="list" style="width: 100%" @row-click="setSku">
                  <el-table-column prop="catName" label="分类"/>
                  <el-table-column prop="skuName" label="名称"/>
                  <el-table-column prop="skuNo" label="货号"/>
                </el-table>
                <div class="pagination">
                  <el-pagination
                    background
                    @current-change="skuPageSearch"
                    :pager-count="7"
                    layout="total, prev, pager, next"
                    :total="skuPagination.count"
                    :page-count="skuPagination.pageCount"
                    :current-page="skuPagination.pageNumber"
                  ></el-pagination>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="成/毛料" name="2">
              <!--工具栏-->
              <div class="head-container">
                <!-- 搜索 -->
                <el-select v-model="categoryId" placeholder="请选择分类" size="mini" style="width: 200px;" clearable
                           class="filter-item">
                  <el-option
                    v-for="item in categoryList"
                    :key="item.id"
                    :label="item.catName"
                    :value="item.id">
                  </el-option>
                </el-select>
                <el-input v-model="skuName" clearable size="mini" placeholder="输入名称" style="width: 200px;"
                          class="filter-item"/>
                <el-input v-model="skuNo" clearable size="mini" placeholder="输入货号" style="width: 200px;"
                          class="filter-item"/>
                <el-button class="filter-item" style="margin-left: 5px" size="mini" type="success" icon="el-icon-search"
                           @click="toSearch">搜索
                </el-button>
              </div>
              <div style="height: 567px;">
                <el-table :data="specs" style="width: 100%" @row-click="setSpec">
                  <el-table-column prop="catName" label="分类"/>
                  <el-table-column prop="skuName" label="名称"/>
                  <el-table-column prop="skuNo" label="货号"/>
                </el-table>
                <div class="pagination">
                  <el-pagination
                    background
                    @current-change="specPageSearch"
                    :pager-count="7"
                    layout="total, prev, pager, next"
                    :total="specPagination.count"
                    :page-count="specPagination.pageCount"
                    :current-page="specPagination.pageNumber"
                  ></el-pagination>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div style="width: 53%;margin-left: 2%;height: 100%">
          <el-card class="box-card">
            <div slot="header" class="clearfix" style="height: 2px;line-height: 2px">
              <span>出库表</span>
            </div>
            <div class="text item" style="height: 612px;">
              <el-table :data="ckbList" style="width: 100%;height: 420px" max-height="420">
                <el-table-column prop="name" label="商品名称"/>
                <el-table-column prop="no" label="货号"/>
                <el-table-column prop="spec" label="颜色/尺码"/>
                <el-table-column prop="type" label="类别">
                  <template slot-scope="scope">
                    <span v-if="scope.row.type==0">卖品</span>
                    <span v-if="scope.row.type==1">非卖品</span>
                    <span v-if="scope.row.type==2">服务商品</span>
                  </template>
                </el-table-column>
                <el-table-column prop="spec" label="数量">
                  <template slot-scope="scope">
                    <div v-if="scope.row.type==2">
                      <div style="display:flex;flex-direction: row">
                        <span style="margin-right: 10px">毛料</span>
                        <span>{{scope.row.mlNum}}</span>
                      </div>
                      <div style="display:flex;flex-direction: row">
                        <span style="margin-right: 10px">成品</span>
                        <span>{{scope.row.cpNum}}</span>
                      </div>
                    </div>
                    <div v-else>
                      <span>{{scope.row.num}}</span>
                    </div>
                  </template>
                </el-table-column>
<!--                <el-table-column prop="amount" label="金额"/>-->
              </el-table>
              <div style="margin-top: 18px">
                <div class="bbbt" style="font-size: 13px;margin-bottom: 12px;display: flex;flex-direction: column">
                  <span style="margin-bottom: 5px">数量小计: {{totalNum}}</span>
                  <!--                <span>金额小计: {{totalAmount}}</span>-->
                </div>
                <div style="display: flex;flex-direction: row;">
                  <div class="bbbt" style="margin-right: 15px">
                    <span class="bbbemp">出库方式:</span>
                    <el-select v-model="srWay" placeholder="请选择出库方式" size="small" style="width: 100%;">
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                  <div class="bbbt" v-if="srWay!=5">
                    <span class="bbbemp">选择员工:</span>
                    <el-select v-model="empId" placeholder="请选择员工" style="width: 100%;" size="small">
                      <el-option
                        v-for="item in emps"
                        :key="item.id"
                        :label="item.nickName"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <el-input v-model="note" placeholder="请输入备注" size="small"
                          style="width: 100%;margin-top: 8px"></el-input>
              </div>
            </div>
            <el-button style="width: 100%;position: relative;bottom: 0px" type="primary"
                       size="small"
                       @click="submitEvent">直接出库
            </el-button>
          </el-card>
        </div>
      </div>

      <!--表单组件-->
      <el-dialog append-to-body :close-on-click-modal="false" :before-close="closeEvent" :visible.sync="visible"
                 :title="title" width="300px">
        <div>
          <el-input v-model="kcNum" clearable size="small" placeholder="输入库存量"/>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="closeEvent">取消</el-button>
          <el-button size="small" type="primary" @click="submitSku">确认</el-button>
        </div>
      </el-dialog>

      <!--表单组件-->
      <el-dialog append-to-body :close-on-click-modal="false" :before-close="closeEvent" :visible.sync="csVisible"
                 :title="title" width="600px">
        <el-input placeholder="请输入规格" v-model="specName" class="input-with-select" size="small" style="margin-top: -25px">
          <el-button slot="append" icon="el-icon-search" @click="sousuo"></el-button>
        </el-input>
        <div class="specs">
          <el-table
            :data="spList"
            @row-click="addSpec"
            height="350px"
            style="width: 100%">
            <el-table-column prop="spec" label="规格"/>
            <el-table-column prop="cpStock" label="成品"/>
            <el-table-column prop="mlStock" label="毛料"/>
          </el-table>
        </div>
        <div style="margin-top: 20px;width: 300px">
          <el-input
            placeholder="未选规格"
            size="mini"
            style="margin-bottom: 8px"
            v-model="spec.spec"
            :disabled="true">
          </el-input>
          <el-input v-model="cpNum" clearable size="mini" placeholder="输入成品库存量" style="margin-bottom: 8px"/>
          <el-input v-model="mlNum" clearable size="mini" placeholder="输入毛料库存量"/>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="closeEvent">关闭</el-button>
          <el-button size="small" type="primary" @click="submitSpec">添加</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import {getToken} from "../../utils/auth";

  export default {
    name: 'cklist',
    components: {},
    data() {
      return {
        specName:'',
        categoryId: '',
        skuName: '',
        skuNo: '',
        categoryList: [],

        loading: false,
        list: [],
        specs: [],

        skuItem: {},
        specItem: {},
        spList: [],
        spec: {},
        tabIndex: -1,

        title: '',
        visible: false,
        kcNum: '',
        csVisible: false,
        cpNum: 0,
        mlNum: 0,


        emps: [],
        depts: [],
        ckbList: [],
        srWay: '3',
        note: '',
        empId: '',
        deptId: '',


        ids: [],

        options: [
          // {name: '员工领用', value: '1'},
          // {name: '员工领购', value: '2'},
          {name: '产品开用', value: '3'},
          {name: '损坏', value: '4'},
          // {name: '产品调出', value: '5'},
        ],

        eidtItem: {},
        delId: '',
        delVisible: false,
        form: {
          id: null,
          adName: null,
          adType: 0,
          adImgUrl: null,
          adLink: null,
          adSort: null,
        },

        active: '0',

        totalNum: 0,
        totalAmount: 0,


        skuPagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },
        specPagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },

      }
    },

    async created() {
      this.getCategoryList()
      this.getSkuList()
      this.getSwEmps()
      // this.getDepts()
    },
    methods: {
      async getCategoryList() {
        let data = {
          token: getToken(),
          catType: 1,
          spec: this.active,
        }
        let res = await this.$get('/sw/getSwCategoryList', data)
        this.categoryList = res.categoryList
      },
      async getSwEmps() {
        let res = await this.$get('/sw/getSwEmps', {token: getToken()})
        this.emps = res.emps
      },

      async getDepts() {
        let res = await this.$get('/sw/getSwDeptAll', {token: getToken()})
        this.depts = res.depts
      },

      lanjie(val) {
        if (this.ckbList.length > 0) {
          if (this.mpType == 'MP') {
            if (val == '2') {
              this.$alert('正在进行成/毛料入库不能切换!', '提示', {
                confirmButtonText: '确定',
              });
              return false;
            }
          } else {
            if (val != '2') {
              this.$alert('正在进行卖品/非卖品入库不能切换!', '提示', {
                confirmButtonText: '确定',
              });
              return false;
            }
          }
        }
        return true;
      },

      tabClick() {
        if (this.active == '0' || this.active == '1') {
          this.categoryId = ''
          this.mpType = 'MP'
          this.getCategoryList()
          this.getSkuList()
        } else {
          this.categoryId = ''
          this.mpType = 'CP'
          this.getCategoryList()
          this.getSkuSpecs()
        }
      },


      toSearch() {
        if (this.active == '0' || this.active == '1') {
          this.getSkuList()
        } else {
          this.getSkuSpecs()
        }
      },

      sousuo() {
        var name = this.specName.replace(/\s+/g, "");
        let specs = []
        this.specItem.skuSpecs.forEach(item => {
          var newStr = item.spec.replace(/\s+/g, "");
          let b = newStr.toUpperCase().match(name.toUpperCase())
          if (b) {
            specs.push(item)
          }
        })
        if (specs.length > 0) {
          this.spList = specs
        } else {
          this.spList = []
        }
      },

      closeEvent() {
        this.visible = false
        this.csVisible = false
      },

      setSku(row) {
        this.kcNum = ''
        this.visible = true
        this.skuItem = row
        this.title = '商品: ' + row.skuName
      },

      setSpec(row) {
        this.specName = ''
        this.spec = {}
        this.cpNum = 0
        this.mlNum = 0
        this.specItem = row
        this.spList = JSON.parse(JSON.stringify(row.skuSpecs))
        this.csVisible = true
        this.title = '商品: ' + row.skuName
      },
      addSpec(spec) {
        this.spec = spec
      },

      submitSku() {
        if (parseInt(this.skuItem.csStock) < parseInt(this.kcNum)) {
          this.$message.error("库存剩余量只有" + this.skuItem.csStock)
          return;
        }
        let amount = parseInt(this.skuItem.originalPrice) * parseInt(this.kcNum)
        let item = {
          id: this.skuItem.id,
          name: this.skuItem.skuName,
          no: this.skuItem.skuNo,
          spec: '无',
          type: this.skuItem.isSell,
          num: this.kcNum,
          mlNum:0,
          cpNum:0,
          amount: amount,
        }
        this.ckbList.push(item)
        this.totalNum = parseInt(this.totalNum) + parseInt(this.kcNum)
        this.totalAmount = parseInt(this.totalAmount + amount)
        this.visible = false
      },


      submitSpec() {
        if (!this.spec.id) {
          this.$alert('未选择规格!', '提示', {
            confirmButtonText: '确定',
          });
          return;
        }
        if (parseInt(this.spec.mlStock) < parseInt(this.mlNum)) {
          this.$msgError("毛料库存不足！")
          return;
        }
        if (parseInt(this.spec.cpStock) < parseInt(this.cpNum)) {
          this.$msgError("成品库存不足!")
          return;
        }
        let num = parseInt(this.mlNum) + parseInt(this.cpNum)
        let amount = parseInt(this.spec.originalPrice) * parseInt(num)
        let item = {
          id: this.spec.id,
          name: this.specItem.skuName,
          no: this.specItem.skuNo,
          spec: this.spec.spec,
          type: 2,
          num: 0,
          mlNum: this.mlNum,
          cpNum: this.cpNum,
          amount: amount,
        }
        this.ckbList.push(item)
        this.totalNum = parseInt(this.totalNum + num)
        this.totalAmount = parseInt(this.totalAmount + amount)
      },

      skuPageSearch(page) {
        this.getSkuList(page)
      },
      async getSkuList(page) {
        let currentPage = page || 1;
        let data = {
          categoryId: this.categoryId,
          condition: this.skuName,
          skuNo: this.skuNo,
          isSell: this.active,
          token: getToken(),
          currentPage,
        }
        let res = await this.$get("/sw/getSwCkSkuList", data)
        let list = res.dataSet.list;
        this.list = list;
        this.skuPagination = {
          count: res.dataSet.count,
          pageNumber: res.dataSet.pageNumber,
          pageSize: res.dataSet.pageSize,
          pageCount: res.dataSet.pages
        }
      },


      specPageSearch(page) {
        this.getSkuSpecs(page)
      },
      async getSkuSpecs(page) {
        let currentPage = page || 1;
        let data = {
          categoryId: this.categoryId,
          condition: this.skuName,
          skuNo: this.skuNo,
          isSell: this.active,
          token: getToken(),
          currentPage,
        }
        let res = await this.$get("/sw/getSwCkSkuSpecs", data)
        this.specs = res.list;
        let list = res.dataSet.list;
        this.specs = list;
        this.specPagination = {
          count: res.dataSet.count,
          pageNumber: res.dataSet.pageNumber,
          pageSize: res.dataSet.pageSize,
          pageCount: res.dataSet.pages
        }
      },


      emptyItem() {
        this.ckbList = []
        this.srWay = '3'
        this.note = ''
        this.empId = ''
        this.deptId = ''
        this.totalNum = 0
        this.totalAmount = 0
      },


      //新增编辑方法
      async submitEvent() {
        let data = {
          slJson: JSON.stringify(this.ckbList),
          srWay: this.srWay,
          note: this.note,
          empName: this.empId,
          dhDeptId: this.deptId,
          quantity: this.totalNum,
          amount: this.totalAmount,
          token: getToken(),
        }
        let res = await this.$post('/glc/ckStockRecord', data)
        if (res.code == 200) {
          this.$message.success(res.message, 3000)
          this.emptyItem()
        } else {
          this.$message.error(res.message, 3000)
        }
      },
    }
  }
</script>

<style scoped>
  .cont {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .bbbt {
    display: flex;
    flex-direction: row;
    width: 50%;
  }

  .bbbemp {
    font-size: 13px;
    margin-right: 1px;
    width: 83px;
    line-height: 32px;
  }

  .specs {
    margin-top:5px;
  }

  .spec-item {
    display: flex;
    flex-direction: column;
    height: 60px;
    padding: 8px 10px;
    border: #A2A2A2 solid 1px;
    margin: 5px;
    cursor: pointer;
    width: calc((100% - 105px) / 6);
    min-width: calc((100% - 105px) / 6);
    max-width: calc((100% - 105px) / 6);
  }

  .spec-item span {
    height: 15px;
    line-height: 15px;
  }

  .hui {
    border: #F35050 solid 2px;
  }

  .app-container {
    margin-top: 18px;
    width: 80%;
    padding-left: 10px;
  }

  .head-container {
    padding-bottom: 10px;
    margin-left: 16px;
  }

  .filter-item {
    display: inline-block;
    vertical-align: middle;
    margin: 0 2px 10px 0;
  }

  .input {
    height: 30.5px;
    line-height: 30.5px;
  }
</style>
